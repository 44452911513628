import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'

import LoadingAnimation from '../loading-animation'
import { logout, selectUserAuth, selectUserData } from '../../app/features/user/userSlice'
import { fetchUser } from '../../app/lib/api'
import { startEngine } from '../../app/features/engine/engineSlice'

export default function WelcomeBack ({ fullScreenHandle }) {
  const router = useRouter()
  const dispatch = useDispatch()
  const user = useSelector(selectUserData)
  const auth = useSelector(selectUserAuth)
  const [loading, setLoading] = useState(true)

  async function goToIntroVideo () {
    try {
      await fullScreenHandle.enter()
      await dispatch(startEngine())
    } catch (error) {
      // Ignore errors if we cannot enter fullscreen
      console.log(error)
    }

    await router.push('/')
  }

  useEffect(() => {
    async function testUser () {
      try {
        const user = await fetchUser(auth)
        if (user?._id) {
          setLoading(false)
          return
        }
      } catch (error) {
        console.log(error)
      }

      dispatch(logout())
    }

    testUser().then()
  })

  return (
    <div className='center'>
      <div className='small box with-logo'>
        {loading && <LoadingAnimation />}

        {!loading && (
          <>
            {user?.fullname && <p>Que bom te ver de novo, <strong>{user?.fullname}</strong>!</p>}
            {!user?.fullname && <p>Que bom te ver de novo!</p>}
            <button onClick={goToIntroVideo}>Entrar</button>
            <button onClick={() => dispatch(logout())}>Entrar com outra conta</button>
          </>
        )}
      </div>
    </div>
  )
}
