import React, { useEffect } from 'react'
import Head from 'next/head'

import OrientationNotice from '../components/orientation-notice'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserAuth, selectUserData } from '../app/features/user/userSlice'

import { updateUserData } from '../app/lib/api'
import { selectStart } from '../app/features/engine/engineSlice'
import WelcomeBack from '../components/welcome-back'

export default function Home ({ fullScreenHandle }) {
  const router = useRouter()
  const dispatch = useDispatch()
  const auth = useSelector(selectUserAuth)
  const user = useSelector(selectUserData)
  const start = useSelector(selectStart)

  useEffect(() => {
    if (!auth) {
      router.push('/login').then()
    } else if (!user) {
      updateUserData(auth, dispatch).then()
    }

    setTimeout(() => {
      document.querySelector('canvas')?.focus?.()
    })
  }, [auth, router, user, dispatch])

  return (
    <>
      <Head>
        <title>Ingredion Training</title>
        <meta name='description' content='Ingredion Training' />
        <link rel='icon' href='/favicon.ico' />
      </Head>

      {!start && auth && <WelcomeBack fullScreenHandle={fullScreenHandle} />}

      <OrientationNotice />
    </>
  )
}
